function AutoGrowTextArea(textField) {
  if (textField.clientHeight < textField.scrollHeight) {
    textField.style.height = textField.scrollHeight + "px";
    if (textField.clientHeight < textField.scrollHeight) {
      textField.style.height = 
        (textField.scrollHeight * 2 - textField.clientHeight) + "px";
    }
  }
}

$(function() {
	files = {};
	var loggedIn = $('.ko-comment-loggedin').val() == 'true';
	var rootPath = $('.ko-comment-rootpath').val();

	$('.comment-input').on('focus', function() {
		$('.poster-info-container').slideDown(200);
	});
	
	$('.icon-upload-trigger').on('click', function(e) {
		e.preventDefault();
		$(this).siblings('.icon-upload').click();
	});
	
	$('.icon-upload').on('change', function(e) {
		//referenced on submit
		files = e.target.files;

		var filePath = $(this).val();
		var fileParts = filePath.split('\\');
		var fileName = fileParts.pop();
		if($.trim(fileName) != '')
			$(".icon-upload-trigger span").html(fileName);
		else
			$(".icon-upload-trigger span").html('Change User Icon');
	});
	
	$('.comment-submit').on('click', function(e)  {
		e.preventDefault();
		//required form field checks
		if($.trim($('.comment-input').val()) == '') {
			$('.comment-input').focus();
			return false;
		}
		
		//double check public required fields if this is public
		if(!loggedIn) {
			if($.trim($('#public_name').val()) == '') {
				$('#public_name').focus();
				return false;
			} else if($.trim($('#public_email').val()) == '') {
				$('#public_email').focus();
				return false;
			} else if($('.g-recaptcha.comment-captcha').length > 0 && $.trim(grecaptcha.getResponse()) == '') {
				return false;
			}
			
			//simple check for emails
			var pattern = new RegExp(/^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/i);
			if(!pattern.test($.trim($('#public_email').val()))) {
				$('#public_email').focus();
				return false;
			}
		}
		
		//togle overlay
		$('.hg-overlay').toggleClass('hide');
		$('.hg-spinner').toggleClass('hide');
		
		//push the file data
		var data = new FormData();
		if(files && files.length > 0) {
			$.each(files, function(key, value){
				data.append(key, value);
			});
		}
		
		//add the other inputs
		$('#hg-comment-form *').filter(':input').each(function(){
		    data.append($(this).attr('name'), $(this).val());
		});

		$.ajax({
			url: '/'+rootPath+'/post-comment',
			type: 'POST',
			cache: false,
			dataType: 'json',
			processData: false,
			contentType: false,
			data: data,
			headers: { 'st': $('#ko-widget-st').val() },
		  success: function(data) {
				//toggle overlay
				$('.hg-overlay').toggleClass('hide');
				$('.hg-spinner').toggleClass('hide');
				$('.hg-comment-post').toggleClass('hide');
				if(data && $.trim(data) == 'true'){
					$('.hg-comment-feedback').toggleClass('hide');
				}
				else if(data && $.trim(data) != 'false' && data.commentHtml) {
					$('.hg-comment-list ul').prepend(data.commentHtml);
				}
			}
		});
	});
});