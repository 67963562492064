$(function(){
	//case insensitive contains
	jQuery.expr[':'].icontains = function(a, i, m) {
		return jQuery(a).text().toUpperCase()
		.indexOf(m[3].toUpperCase()) >= 0;
	};

	$('.ko-collapse-trigger').on('click', function(){
		$(this).find('i').toggleClass('fa-plus-circle').toggleClass('fa-minus-circle');
		$(this).siblings('.ko-collapse-article').slideToggle();
	});
	
	$('.topic-toc-item').on('click', function(){
		var targetAnchor = $(this).find('a').attr('href').replace('#', '');
		var target = $('[name="'+targetAnchor+'"]');
		if(target.length > 0 && target.parent().find('.fa-plus-circle').length > 0)
			target.parent().click();
	});
	
	if($('.ko-collapse-trigger').length > 0 && window.location.hash) {
		var hash = window.location.hash.replace('#', '');
		var target = $('[name="'+hash+'"]');
		if(target.length > 0 && target.parent().find('.fa-plus-circle').length > 0)
			target.parent().click();
	}

	$('#pw-reset-link').on('click', function(){
		$('#email').val($('#username').val());
	});
	
	$('.faq-cat-container').on('click', function(e){
		e.preventDefault();
		if($(this).attr('target') == '_blank')
			window.open($(this).data('href'), '_blank');
		else
			window.location.href = $(this).data('href');
	});
	
	$('.faq-cat-panel').on('click', function(e) {
		e.stopPropagation();
		var link = $(this).find('a');
		if(link.attr('target') == '_blank')
			window.open(link.attr('href'), '_blank');
		else
			window.location.href = link.attr('href');
	});

	$('.faq-cat-panel a').on('click', function(e) {
		e.stopPropagation();
	});
	
	if($('.hg-notfound-page').length > 0) {
		$('#notfound-search-go').on('click', function(){
			$('#notfound-search-form').submit();
		});
		
		var initHash = document.location.hash;
		
		if($.trim(initHash) != '' && initHash != '#undefined'){
			initHash = initHash.replace('#', '');
			title = initHash.replace(/\-/g, ' ');
			$('#phrase-search').val(title);
		}
	}
	
	//js print
	$('.ko-js-print').on('click', function(){
      	var article = $('.documentation-article').clone();
        article.find('.hg-article-footer').remove();
      	article.find('script').remove();
      	article.find('[id^="survey-wrapper"]').remove();
      	article.find('.hg-article-controls').remove();
        article = article.html();
		
      	var headClone = $('head').clone();
      	headClone.find('script').remove();
    	var w = window.open();
        var windowHTML = "\<!DOCTYPE HTML PUBLIC '-//W3C//DTD HTML 4.01 Transitional//EN' 							'http://www.w3.org/TR/html4/loose.dtd'>\n\<html>\n\<head>" + headClone.html() + "\n\ </head>\n\
<body style='padding:2em'>\n\<div class='documentation-article' style='box-shadow:none;'>" + article + "\n\
</div>\n\</body>\n\
  </html>";
        
        w.document.write(windowHTML);
      	w.document.close();
        
        $(w).load(function(){
        	w.print();
          	w.close();
        });
	});
	
	//wrap glossary terms
	if(window.ko_glossary_terms) {
		var termCounts = {};
		$.each(window.ko_glossary_terms, function(name, def){
			//span, p, li, em, strong, and u elements for now
			$('.hg-article-body p:icontains("'+name+'"), .hg-article-body span:icontains("'+name+'"), .hg-article-body li:icontains("'+name+'"), .hg-article-body em:icontains("'+name+'"), .hg-article-body strong:icontains("'+name+'"), .hg-article-body u:icontains("'+name+'")').each(function(){
				if($(this).hasClass('ko-glossary-term') || termCounts[name] >= window.ko_glossary_count || $(this).closest('pre').length > 0 || $(this).closest('code').length > 0)
					return false;

				//make sure this isn't in an image caption
				if($(this).parents('.fr-img-caption').length > 0)
					return true;
					
				//handle glossary definitions that have their own name inside of them....so fun!!!!!
				var inceptionReg = new RegExp("\\b("+name+")\\b(?!\{\{placeholder\}\})", "gi");
				def = def.replace(inceptionReg, '$1{{placeholder}}');
				
				//replace all found or just the first based on the counts
				var curTermCount = termCounts[name] ? termCounts[name] : 0;
				var singleReg = new RegExp("\\b("+name+")\\b(?!\{\{placeholder\}\})", "i"); //match first term only and ignore ones already replaced
				if(window.ko_glossary_count - curTermCount > 1)	{
					var reg = new RegExp("\\b("+name+")\\b", "gi"); //match all terms
					var partialReg = new RegExp("\\b(_"+name+"_)\\b", "gi"); //match the special terms we found
				} else {
					var reg = new RegExp("\\b("+name+")\\b", "i"); //match first term
					var partialReg = new RegExp("\\b(_"+name+"_)\\b", "i"); //match the special terms we found
				}
				
				//replace only the text child nodes
				$(this).contents().each(function(){
					if(this.nodeType === 3 && $.trim(this.nodeValue) && reg.test(this.nodeValue) && (!termCounts[name] || termCounts[name] < window.ko_glossary_count)) {
						var matchCount = this.nodeValue.match(reg).length;
						var remMatches = window.ko_glossary_count - termCounts[name];
						
						//only replace the remaining matches left
						if(remMatches < matchCount) {
							//weird one off case where "<something" tricks the js into thinking it's an html element
							var newNode = this.nodeValue.replace(/</g, "&lt;");
							var i = 0;
							while(i < remMatches) {
								var newNode = newNode.replace(
									singleReg
									,'<span class="ko-glossary-term" data-toggle="popover" data-content="'+ def.replace(/"/g, "'") + '">$1{{placeholder}}</span>' 
								);
								i++;
							}
							//clean up the placeholders
							newNode = newNode.replace(/\{\{placeholder\}\}/g, '');
						} else {
							//weird one off case where <something tricks the js into thinking it's an html element
							var newNode = this.nodeValue.replace(/</g, "&lt;");
							newNode = newNode.replace(
								reg
								,'<span class="ko-glossary-term" data-toggle="popover" data-content="'+ def.replace(/"/g, "'") + '">$1</span>' 
							);
							//clean up the placeholders
							newNode = newNode.replace(/\{\{placeholder\}\}/g, '');
						}

						//replace the full node
						$(this).replaceWith(newNode);
						
						termCounts[name] = termCounts[name] ? termCounts[name] + matchCount : matchCount;
					}
				});
			});
		});
	}

	//enable glossary term replacements
	$('.ko-glossary-term').popover({
		trigger: 'manual',
		placement: 'auto top',
		container: 'body',
		html: true,
		template: '<div class="popover ko-glossary-popover"><div class="arrow"></div><h3 class="popover-title"></h3><div class="popover-content"></div></div>'
	}).on("mouseenter", function () {
	    var _this = this;
	    $(this).popover("show");
	    $(".popover").on("mouseleave", function () {
	        $(_this).popover('hide');
	    });
	}).on("mouseleave", function () {
	    var _this = this;
	    setTimeout(function () {
	        if (!$(".popover:hover").length) {
	            $(_this).popover("hide");
	        }
	    }, 50);
	});

	// Focus on table of contents when keyboard navigating
	$('.ko-slideout-left-toggle').attr('aria-label','Open table of contents menu');
	$('.ko-slideout-left-toggle').on('click', function() {
		var button = $(this);
		if (button.find('i').hasClass('fa-bars')) {
		  	$('.ko-slideout-left-toggle').attr('aria-label','Close table of contents menu');
			setTimeout(function() { 
				$('#ko-documentation-categories').find('.documentation-outter-list .documentation-category').first().trigger('focus'); 
			}, 500);
	  	} else {
		  	$('.ko-slideout-left-toggle').attr('aria-label','Open table of contents menu');
	   }
	});
});