$(function(){
	var data = {contactID: $('.ko-contactID').val()};
	var rootpath = $('input[name ="rootpath"]').val();

	$('.results-nope').on('click', function(){
		window.scrollTo(0, 0);
		$('.recommended-container').addClass('hide');
		$('.hg-contact-form-container').removeClass('hide');
	});
	$('.results-yep').on('click', function(){
		$.ajax({
			url: rootpath + '/contact-success',
			type: 'post',
			data: data,
		}).success(function(data){
			
		});
		
		$('.recommended-container').addClass('hide');
		$('.success-container').removeClass('hide');
	});

	$('.results-show').on('click', function(){
		$('.recommended-container').removeClass('hide');
		$('.hg-contact-form-container').addClass('hide');
	});
	//recaptcha
	if($('.g-recaptcha.contact-captcha').length > 0) {
		$('#ko-contact-form [type="submit"]').on('click', function(e){
			if($.trim(grecaptcha.getResponse()) == '') {
				e.preventDefault();
			}
		})
	}
	//prevent dupes
	var cform = $('#ko-contact-form');
	cform.on('submit', function(){
		cform.on('submit', function(){
			$('#cform-submit').prop('disabled', true);
				return false;
			});
		});
	
	$('#contact_attachment').change(function(){
		$('.ko-file-error').not('.hide').addClass('hide');
		if(this.files.length > 10) {
		    this.value = '';
		    $('.ko-file-error').removeClass('hide');
		}      
	});
});