$(function(){
	$(".category-dropdown .dropdown-menu").on("click", function(e){
		e.stopPropagation();
	});
	$(".dropdown-menu .sub-trigger").on("click",function(e){
		var pos = $(this).position();
		var top = pos.top - 42 > 0 ? pos.top - 42 : 0;
		$('.hg-search-bar .dropdown-menu .sub-menu').css('top', top + 'px');
		var current=$(this).next();
		var grandparent=$(this).parent().parent();
		
		if(current.is(':visible'))
			current.hide();
		else {		
			grandparent.find(".sub-menu:visible").not(current).hide();
			current.show();
		}
		
		e.stopPropagation();
	});
	
	//autocomplete search
	var sBars = 1;
	var pathName = window.location.pathname.split('/');
	pathName = pathName[1];
	if(!pathName || pathName.length < 1 || $.inArray(pathName, ['help', 'home', 'docs']) < 0)
		pathName = 'help';

	$('.hg-search-bar').not('.no-as').each(function(){
		var that = $(this);
		$(this).addClass('hg-search-bar-' + sBars);
		$(this).find(':text').autocomplete({
			source: function(request, response) {
	            $.ajax({
	                url: "/" + pathName + "/search-article-names",
	                dataType: "json",
	                data: {
	                    term: request.term,
	                    current: $('#article-id').val() || '',
	                    categories: getRestrictedIds(that)
	                },
	                success: function(data) {
	                	if(data.length > 0) {
	                		data.push({
	                			id: '-1',
	                			value: '',
                    			label: translationsForJs.search["autocomplete-call-to-action"],
                    			feedback: true
                    		});
                    	}
	                    response(data);
	                }
	            });
	        },
		    minLength: 1,
		    appendTo: '.hg-search-bar-' + sBars,
		    focus: function ( event, ui ) {
		    	if(ui.item.feedback)
		    		return false;
		    },
		    select: function( event, ui ) {
		    	if(ui.item.feedback) {
		    		event.preventDefault();
		    		$(event.target).closest('form').submit();
		    	}
		    	else if(ui.item.extLink)
		    		window.open('/'+pathName+'/'+ui.item.permaLink, '_blank');
			    else if(ui.item.permaLink)
			    	window.location = '/'+pathName+'/'+ui.item.permaLink;
			    else if(ui.item.tagSearch)
			    	window.location = '/'+pathName+'/search?phrase=' + ui.item.tagSearch;
		    }
		}).data("ui-autocomplete")._renderItem = function(ul, item) {
		    var listItem = $("<li>" + item.label + "</li>")
		        .data("item.autocomplete", item)
		        .appendTo(ul);

		    //use the clean title as the value
		    item.value = item.cleanLabel;

		    //contains a highlighted term
		    if(item.highlighted)
		    	listItem.addClass("highlighted");

		    //press enter to search message
		    if (item.feedback)
		        listItem.addClass("ko-ac-action-msg");

    		return listItem;
		};

		sBars++;
	});

	//Create array of category IDs that the autocomplete should use when it searches
	getRestrictedIds = function(curSearchBar) {
		if(curSearchBar && curSearchBar.find('.category-dropdown').length > 0 && curSearchBar.find('.category-dropdown input:checked').length > 0) {
			var categoryIDs = [];
			curSearchBar.find('.category-dropdown input:checked').each(function(){
				categoryIDs.push($(this).val());
			});
			return categoryIDs;
		} else {
			return '';
		}
	}
});