$(function(){
	var curCatFilterIds = $('.category-filter-list :checkbox:checked').map(function(){
    	return $(this).val();
    }).get();
    var upCatFilterIDs = [];

    //category filter update
	if($('.hg-search-page').length > 0) {
		$('.category-filter-list :checkbox').on('change', function(){
			upCatFilterIDs = $('.category-filter-list :checkbox:checked').map(function(){
		    	return $(this).val();
		    }).get();
		    if(!compareArrays(curCatFilterIds, upCatFilterIDs))
		    	$('.ko-cat-refine-refresh').removeClass('hide');
		    else
		    	$('.ko-cat-refine-refresh').not('.hide').addClass('hide');
		})
	}

	//trigger refresh after category filter change
	$('.ko-cat-refine-refresh').on('click', function(){
		if($(this).hasClass('disabled'))
			return false;
		$(this).addClass('disabled');
		$('#homepage-search-form').submit();
	});
	
	//sort triggers refresh
	$('#ko-search-sort').on('change', function(){
		$('#homepage-search-form').submit();
	});

	//compare 2 arrays
	function compareArrays(arr1, arr2) {
		return $(arr1).not(arr2).length == 0 && $(arr2).not(arr1).length == 0;
	};
});