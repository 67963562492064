$(function(){
	var rootPath = $('.ko-rating-rootpath').val();
	if($('.hg-ratings .hg-star-rating').length > 0) {
		//attach the hover events for stars
		$('.hg-ratings .hg-star-rating').each(function(i) {
			var star = $(this);					
			//add hover events to each star -- highlight all previous stars
			star.hover( 
				function() {
					star.addClass('hg-star-on');
					star.prevAll('.hg-star-rating').addClass('hg-star-on');
				},
				function() {
					star.removeClass("hg-star-on");
					star.prevAll('.hg-star-rating').removeClass('hg-star-on');
				}
			);
		});
	}
	
	//show thank you message after feedback
	$('.hg-ratings a').on('click', function(e){
		e.preventDefault();
		
		$('.hg-rating-container').addClass('hide');
		$('.hg-rating-success').removeClass('hide');
		
		var rating = $(this).data('rating');
		var pid = $('.ko-rating-pid').val();
		var acntID = $('.ko-rating-acntID').val();
		var aid = $('.ko-rating-aid').val();
		$.ajax({
			url: '/'+rootPath+'/feedback/pid/'+pid+'/acc/'+acntID+'/aid/'+aid+'/rating/' + rating,
			headers: { 'st': $('#ko-widget-st').val() }
		});
	});			
});

